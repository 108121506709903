* {
  box-sizing: border-box;
}
.App {
  text-align: center;
}
body {
  margin: 0 !important;
}

.dragging {
  border: 2px solid black !important;
}
.dragging:active {
  cursor: 'grabbing' !important;
}
.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}
.color-picker {
  position: relative;
}

.color-picker-edit {
  cursor: pointer;
  margin-left: 5px;
}

.material-symbols-outlined {
  cursor: pointer;
  font-size: 14px;
}

.color-picker-popup {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 50%;
  cursor: crosshair;
}

.color-picker-close {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  background: white;
  border: none;
  outline: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}
.color-picker-buttons {
  position: relative;
  top: -35px;
}
.chrome-picker {
  padding-bottom: 30px;
}
